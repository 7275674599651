<template>
  <AppBarLayout
    class="IssueAppBar"
  >
    <router-link
      v-ripple="{ class: 'app-ripple' }"
      class="IssueAppBar__title page-title app-bar__link mr-4"
      :to="backRoute"
      exact
      active-class="_"
    >
      <v-btn
        icon
        color="#8492A6"
      >
        <v-icon
          size="16"
          v-text="'$left'"
        />
      </v-btn>

      <!-- it doesn't work, so disabling for now -->
      <span
        v-if="false"
        v-show="cardId"
        class="page-title__card-badge"
        :style="{ ...cardStyles }"
        v-text="$t('issue.card')"
      />

      <span
        class="page-title__title ml-2"
        v-text="title"
      />
      <span
        class="page-title__counter ml-2"
        v-text="issueOrder && issueOrder.length"
      />
    </router-link>

    <template #actions>
      <div class="IssueAppBar__actions d-flex flex-nowrap">
        <v-btn
          outlined
          color="primary"
          :to="previousRoute"
          :disabled="!previousRoute"
        >
          {{ $t('issue.Previous') }}
        </v-btn>
        <v-btn
          outlined
          class="ml-2"
          color="primary"
          :to="nextRoute"
          :disabled="!nextRoute"
        >
          {{ $t('issue.Next') }}
        </v-btn>
      </div>
    </template>
  </AppBarLayout>
</template>

<script>
import * as R from 'ramda'

import { BG, COLORS_LOOKUP } from '../constants'

import DashboardCard from '../store/orm/dashboardCard'
import Issue from '../store/orm/issue'

import keyboardNavigation from '../mixins/keyboardNavigation'

import AppBarLayout from '../layouts/AppBarLayout'

export default {
  name: 'IssueAppBar',

  components: {
    AppBarLayout,
  },

  mixins: [
    keyboardNavigation(),
  ],

  props: {
    projectId: { type: String, default: null },
    issueId: { type: String, required: true },
    cardId: { type: Number, default: null },
  },

  computed: {
    card() {
      const { cardId } = this
      return cardId && DashboardCard.find(cardId)
    },

    issues() {
      const { projectId } = this
      const q = Issue.query().withAllRecursive()
      return projectId
        ? q.where('projectID', projectId).all()
        : q.all()
    },

    listOrder() { return this.$store.state.entities.issue.$visibleOrder },

    issueOrder() {
      const { issueId, issues, listOrder } = this
      return listOrder.includes(issueId)
        ? listOrder
        : (issues && issues.map(R.prop('id')))
    },

    backRoute() {
      const { $route, projectId, cardId } = this
      if (projectId) {
        return cardId
          ? {
            name: 'ProjectCardIssueList',
            params: { projectId, cardId },
            query: R.omit(['issueId', 'chat'], $route.query),
          }
          : {
            name: 'ProjectIssueList',
            params: { projectId },
            query: R.omit(['issueId', 'chat'], $route.query),
          }
      }
      return cardId
        ? {
          name: 'CardIssueList',
          params: { cardId },
          query: R.omit(['issueId', 'chat'], $route.query),
        }
        : {
          name: 'IssueList',
          query: R.omit(['issueId', 'chat'], $route.query),
        }
    },

    // card styles: color, bg
    color() {
      const { card } = this
      const color = card && card.metaData && card.metaData.color
      const colorKey = color && R.has(color, COLORS_LOOKUP)
        ? color
        : null
      return colorKey && COLORS_LOOKUP[colorKey]
    },

    bg() {
      const { card } = this
      const pattern = card && card.metaData && card.metaData.pattern
      const patternKey = pattern && R.has(pattern, BG)
        ? pattern
        : null
      return patternKey && BG[patternKey]
    },

    cardStyles() {
      const { color, bg } = this

      if (!color || !bg) return {}
      return {
        backgroundColor: color.color,
        backgroundImage: `url(${bg.image})`,
      }
    },

    title() {
      const { cardId, card } = this
      if (!cardId) return this.$t('issue.Back')
      return card ? card.name : '...'
    },

    currentPosition() { // Maybe<int>
      const { issueId, issueOrder } = this

      if (!issueOrder) return null
      const pos = issueOrder.indexOf(issueId)
      return pos === -1 ? null : pos
    },

    previousRoute() {
      const { $route, issueOrder, currentPosition: ix } = this
      if (!issueOrder || !ix || ix <= 0) return null

      const previousIssueId = issueOrder[ix - 1]
      return R.pipe(
        R.assocPath(['params', 'issueId'], previousIssueId),
        R.pick(['name', 'params', 'query']),
        R.over(R.lensProp('query'), R.omit(['issueId'])),
      )($route)
    },

    nextRoute() {
      const { $route, issueOrder, currentPosition: ix } = this
      if (!issueOrder || ix === null || ix >= (issueOrder.length - 1)) return null

      const nextIssueId = issueOrder[ix + 1]
      return R.pipe(
        R.assocPath(['params', 'issueId'], nextIssueId),
        R.pick(['name', 'params', 'query']),
        R.over(R.lensProp('query'), R.omit(['issueId'])),
      )($route)
    },
  },
}
</script>

<style lang="sass" scoped>
.IssueAppBar
  .page-title
    display: flex
    align-items: center
    text-decoration: inherit
    color: inherit
    overflow: hidden
    text-overflow: ellipsis
    // border-left: 1px solid rgba(0, 0, 0, .05)

    &__card-badge
      padding: 6px
      border-radius: 2px
      background-repeat: repeat
      background-size: 33%
      color: white

    &__title
      color: #3c3a52
      overflow: hidden
      text-overflow: ellipsis
      font-size: 24px
      line-height: 32px
      font-weight: 500

    &__counter
      color: #8b90a0
      overflow: hidden
      text-overflow: ellipsis
      flex-shrink: 0
      font-size: 24px
      line-height: 32px
      font-weight: 500
</style>
