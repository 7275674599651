<template>
  <div class="Issue">
    <IssueAppBar
      :project-id="projectId"
      :issue-id="issueId"
      :card-id="cardId"
    />

    <div
      class="Issue__columns"
      :style="chatDrawer && $vuetify.breakpoint.lgAndUp ? { marginRight: '540px'} : {}"
    >
      <aside class="Issue__side-info">
        <v-toolbar
          class="Issue__toolbar"
          dark
          color="darkBackground"
        >
          <v-menu
            :disabled="saving || !canEditIssue"
            transition="slide-y-transition"
          >
            <template #activator="{ on, attrs }">
              <div
                class="Issue__status"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar
                  :color="statusColor"
                  size="8"
                />
                <span
                  class="ml-2"
                  v-text="status ? status.displayName : (issue && (issue.status || '<unset>')) /* unknown status */"
                />
                <v-btn
                  v-if="canEditIssue"
                  icon
                  small
                  :loading="saving"
                >
                  <v-icon v-text="'$edit'" />
                </v-btn>
              </div>
            </template>

            <v-list>
              <v-list-item
                v-for="status in issueStatus"
                :key="status.name"
                :input-value="form && status.name === (form || {}).status"
                :ripple="{ class: 'app-ripple' }"
                @click="setIssueStatus(status.name)"
              >
                <v-list-item-content :class="{ 'font-weight-bold': status.name === (form || {}).status }">
                  <v-list-item-title>{{ status.displayName }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <SlaBadge
            v-if="issue != null && slaConfig != null"
            class="ml-auto"
            :issue-id="issue.id"
            :sla-date="issue.sla"
            :completed-datetime="issue.completed"
            :start-datetime="issue.created"
            :manually-overriden="issue.slaManualSet != null"
            :editable="canEditIssue"
            @mousedown.stop
            @click.prevent
          />
        </v-toolbar>

        <div
          v-if="issue"
          class="Issue__additional additional"
        >
          <section class="additional__section d-flex align-center justify-space-between">
            <ColorScore
              :score="issue.totalScore"
              dense
            />

            <div class="d-flex align-center ml-3">
              <div
                class="additional__title-text mr-1"
                v-text="truncate(getFieldDisplayName('probabilityScore') || $t('issue.ProbabilityShort'), 4, '.')"
              />
              <TriScore :score="issue.probabilityScore" />
            </div>

            <div class="d-flex align-center ml-3">
              <div
                class="additional__title-text mr-1"
                v-text="truncate(getFieldDisplayName('criticalityScore') || $t('issue.CriticalityShort'), 4, '.')"
              />
              <TriScore :score="issue.criticalityScore" />
            </div>
          </section>

          <section
            v-if="fieldsLookup && fieldsLookup.ips"
            class="additional__section"
          >
            <h6
              class="additional__title"
              v-text="getFieldDisplayName('ips')"
            />
            <div
              v-for="(ip, i) in visibleIps"
              :key="`${issue.id}-ip-${i}`"
              class="text-truncate"
              :title="ip"
              v-text="ip"
            />
            <v-hover v-if="ipsExpandable">
              <template #default="{ hover }">
                <v-btn
                  :color="hover ? '#FFB950' : '#69D2EF'"
                  text
                  class="ml-n4"
                  @click="ipsExpanded = !ipsExpanded"
                >
                  {{ ipsExpanded ? $t('issue.ShowLess') : $t('issue.ShowAll', { items: ips.length }) }}
                </v-btn>
              </template>
            </v-hover>
          </section>

          <section
            v-if="fieldsLookup && fieldsLookup.hostnames"
            class="additional__section"
          >
            <h6
              class="additional__title"
              v-text="getFieldDisplayName('hostnames')"
            />
            <div
              v-for="(hostname, i) in visibleHostnames"
              :key="`${issue.id}-hostname-${i}`"
              class="text-truncate"
              :title="hostname"
              v-text="hostname"
            />
            <v-hover v-if="hostnamesExpandable">
              <template #default="{ hover }">
                <v-btn
                  :color="hover ? '#FFB950' : '#69D2EF'"
                  text
                  class="ml-n4"
                  @click="hostnamesExpanded = !hostnamesExpanded"
                >
                  {{ hostnamesExpanded ? $t('issue.ShowLess') : $t('issue.ShowAll', { items: ips.length }) }}
                </v-btn>
              </template>
            </v-hover>
          </section>

          <section
            v-if="files.length"
            class="additional__section"
          >
            <h6
              class="additional__title"
              v-text="$t('issue.Files')"
            />
            <a
              v-for="file in files"
              :key="file.uuid"
              :href="file.url"
              download
              class="Issue__attachment additional__attachment"
              @click.prevent="galleryFileUuid = file.uuid"
              v-text="file.fileName"
            />
            <v-hover>
              <template #default="{ hover }">
                <v-btn
                  :color="hover ? '#FFB950' : '#69D2EF'"
                  text
                  class="ml-n4"
                  @click="downloadAll()"
                >
                  {{ $t('issue.DownloadAll') }}
                </v-btn>
              </template>
            </v-hover>
          </section>
        </div>
      </aside>

      <!-- prevent `keydown` propagation to stay on issue page
      while editing markdown and using [<-] and [->] -->
      <article
        class="Issue__content"
        @keydown.stop
      >
        <header
          class="Issue__counter"
          v-text="counterText"
        />

        <h1 class="Issue__title">
          <span v-text="issue && issue.name" />
        </h1>

        <IssueExportRecords
          v-if="issue && issue.exportRecords && issue.exportRecords.length"
          :records="issue.exportRecords"
          class="Issue__export-records"
        />

        <div class="Issue__toc">
          <div
            v-for="tocItem in toc"
            :key="tocItem.href"
            class="Issue__toc-item"
          >
            <a
              :href="tocItem.href"
              v-text="tocItem.text"
            />
          </div>
        </div>

        <template v-if="issue">
          <IssueField
            v-for="field in dynamicFields"
            :id="'issue-' + field.name"
            :key="field.name"
            :field="field"
            :issue="issue"
            class="Issue__field"
          />

          <IssueStatusHistory
            v-if="issue != null"
            class="Issue__status-history"
            :status-history="issue.statusHistory"
            inline-styles
            platform-name="frigate"
            :project-id="issue.projectID"
          />
        </template>
      </article>
    </div>

    <!-- chat FAB -->
    <v-fab-transition appear>
      <v-btn
        v-show="!chatDrawer"
        color="#404059"
        dark
        fixed
        bottom
        right
        width="64"
        min-width="64"
        height="64"
        class="rounded-lg"
        :to="chatRoute"
      >
        <v-icon color="#69D2EF">
          mdi-message
        </v-icon>
      </v-btn>
    </v-fab-transition>

    <GalleryDialog
      v-model="galleryFileUuid"
      :is-open.sync="galleryIsOpen"
      :files="files"
    />
  </div>
</template>

<script>
import { IssueStatusHistory } from '@hexway/shared-front'
import * as R from 'ramda'

import { axiosInstance as axios } from '../api'
import {
  CARD_TYPE,
  ISSUE_FIELD_TYPE,
  ISSUE_CUSTOM_WIDGET_FIELDS,
} from '../constants'
import { issueFilterQueryToObject, reportError, replaceRoute, handleError } from '../helpers'

import Dashboard from '../store/orm/dashboard'
import DashboardCard from '../store/orm/dashboardCard'
import Issue from '../store/orm/issue'
import IssueStatusChange from '../store/orm/issueStatusChange'
import SlaConfig from '../store/orm/slaConfig'

import ColorScore from '../components/ColorScore'
import GalleryDialog from '../components/GalleryDialog'
import IssueExportRecords from '../components/IssueExportRecords'
import IssueAppBar from '../components/IssueAppBar'
import IssueField from '../components/IssueField'
import SlaBadge from '../components/SlaBadge'
import TriScore from '../components/TriScore'

const MAX_IPS = 5
const MAX_HOSTNAMES = MAX_IPS

const formFields = [
  'status',
]

export default {
  name: 'Issue',

  components: {
    ColorScore,
    GalleryDialog,
    IssueExportRecords,
    IssueAppBar,
    IssueField,
    IssueStatusHistory,
    SlaBadge,
    TriScore,
  },

  metaInfo() {
    const { $store, issue } = this
    return {
      title: $store.getters.title(issue ? issue.name : this.$t('issue.LoadingIssue')),
    }
  },

  props: {
    projectId: { type: String, default: null },
    issueId: { type: String, required: true },
    cardId: { type: Number, default: null },
    filter: { type: Object, default: null },
    chatDrawer: { type: Boolean, default: false },
  },

  data: () => ({
    form: null,
    saving: false,
    ipsExpanded: false,
    hostnamesExpanded: false,
    galleryFileUuid: null,
  }),

  computed: {
    dashboardId() { return this.projectId || Dashboard.GLOBAL_ID },

    issueStatus() {
      const { $store, issue } = this
      return issue && $store.getters['$issueStatus/getList'](issue.projectID)
    },
    issueStatusLookup() { // lookup of all possible issue status with pretty names
      const { $store, issue } = this
      return issue && $store.getters['$issueStatus/getLookup'](issue.projectID)
    },

    slaConfig() {
      const { issue } = this
      return issue && SlaConfig.query().withAll().find(issue.projectID)
    },

    issue() { return Issue.query().withAllRecursive().find(this.issueId) },
    canEditIssue() { return this.issue && this.issue.canBeEditedByCurrentUser() },
    issues() {
      const { projectId } = this
      const q = Issue.query().withAllRecursive()
      return projectId
        ? q.where('projectID', projectId || null).all()
        : q.all()
    },

    fields() {
      const { $store, issue } = this
      return issue && $store.getters['issueSchema/fields'](issue.projectID)
    },
    fieldsLookup() {
      const { $store, issue } = this
      return issue && $store.getters['issueSchema/fieldsLookup'](issue.projectID)
    },

    dynamicFields() {
      const { fields } = this
      return fields && fields
        .filter(f => !ISSUE_CUSTOM_WIDGET_FIELDS.includes(f.name))
    },

    currentPosition() { // Maybe<int>
      const { issueId, issues } = this

      if (!issues) return null
      const pos = R.findIndex(R.propEq('id', issueId), issues)
      return pos === -1 ? null : pos
    },

    card() {
      const { cardId } = this
      return cardId && DashboardCard.find(cardId)
    },

    cardQuery() {
      const { card } = this
      const q = card && {
        [CARD_TYPE.counter]: 'query.query',
        [CARD_TYPE.aOfB]: 'query.mainQuery',
        [CARD_TYPE.pieChart]: 'query.query',
        [CARD_TYPE.table]: 'query.query',
      }[card.cardType]
      return q ? issueFilterQueryToObject(q) : null
    },

    status() {
      const { issueStatusLookup, issue, form } = this
      const status = form ? form.status : issue && issue.status
      return status && issueStatusLookup && (issueStatusLookup[status] || null)
    },

    statusColor() {
      const { issueStatusLookup, issue, form } = this
      const status = form ? form.status : issue && issue.status
      if (!status) return 'grey'
      return issueStatusLookup?.[status]?.color || 'grey'
    },

    counterText() {
      const { issues, currentPosition: ix } = this
      return issues && ix !== null
        ? `${ix + 1} from ${issues.length}`
        : null
    },

    ips() { return this.issue.ips || [] },

    ipsExpandable() {
      const { ips } = this
      return ips.length > MAX_IPS
    },

    visibleIps() {
      const { ips, ipsExpanded } = this
      return ipsExpanded ? ips : ips.slice(0, MAX_IPS)
    },

    hostnames() { return this.issue.hostnames || [] },

    hostnamesExpandable() {
      const { hostnames } = this
      return hostnames.length > MAX_HOSTNAMES
    },

    visibleHostnames() {
      const { hostnames, hostnamesExpanded } = this
      return hostnamesExpanded ? hostnames : hostnames.slice(0, MAX_HOSTNAMES)
    },

    files() {
      return this.issue?.attachments || []
    },

    galleryIsOpen: {
      get() { return this.galleryFileUuid != null },
      set(isOpen) {
        this.galleryFileUuid = isOpen
          ? this.files[0]?.uuid ?? null
          : null
      },
    },

    chatRoute() {
      const { $route } = this
      return R.pipe(
        R.pick(['name', 'params', 'query', 'hash']),
        R.mergeDeepLeft({ query: { chat: 'on' } }),
      )($route)
    },

    toc() {
      const { dynamicFields, issue } = this

      if (!issue || !dynamicFields) return null

      const getValue = (fieldName) =>
        Issue.hardcodedFields.includes(fieldName)
          ? issue[fieldName]
          : issue.data[fieldName]

      const isEmptyValue = (field, value) =>
        [
          ISSUE_FIELD_TYPE.INTEGER,
          ISSUE_FIELD_TYPE.FLOAT,
          ISSUE_FIELD_TYPE.BOOLEAN,
        ].includes(field.type)
          ? value != null
          : !value

      const isEmptyField = field =>
        field.isList
          ? !getValue(field.name)?.length
          : isEmptyValue(field, getValue(field.name))

      return R.pipe(
        R.map(field => !isEmptyField(field) && {
          text: field.displayName,
          href: `#issue-${field.name}`,
        }),
        R.filter(Boolean),
      )(dynamicFields)
    },

    notificationDrawer() {
      return this.$store.state.appDrawer.notificationDrawer
    },

    closeChatRoute() {
      const queryLens = R.lensProp('query')
      return R.pipe(
        R.pick(['name', 'params', 'query', 'hash']),
        R.over(queryLens, R.omit(['chat'])),
      )(this.$route)
    },
  },

  watch: {
    issueId: {
      handler: 'loadData',
      immediate: true,
    },

    issue: {
      handler: 'initForm',
      immediate: true,
    },

    'notificationDrawer'(isOpen) {
      isOpen && this.chatDrawer && replaceRoute(this.$router, this.closeChatRoute)
    },
  },

  methods: {
    async loadData() {
      try {
        await Promise.all([
          this.loadIssue(),
          this.loadMaybeCardAndIssues(),
          this.loadStatusHistory(),
        ])
        // following methods depend on issue being loaded
        // to get `issue.projectID` from it
        await Promise.all([
          this.loadSchema(),
          this.loadIssueStatuses(),
          this.loadSlaConfig(),
          this.loadPermissions(),
        ])
      } catch (e) {
        await reportError(e)
      }
    },

    async loadMaybeCardAndIssues() {
      const { cardId } = this

      if (cardId) {
        await this.loadCard() // card is required to load its issues
        if (this.issues === null) {
          await this.loadCardIssues()
        }
      } else {
        if (this.issues === null) {
          await this.loadCommonIssues()
        }
      }
    },

    loadIssue(payload = { reload: true }) {
      const { issueId } = this
      return Issue.dispatch('$getDetails', { issueId, ...payload })
    },
    loadCardIssues() {
      const {
        projectId,
        cardQuery,
        filter,
      } = this

      const requestFilter = R.filter(
        Boolean, // values aren't empty
        R.mergeDeepRight(cardQuery, filter || {}),
      )

      return Issue.dispatch('$getList', {
        projectId,
        filter: requestFilter,
      })
    },
    loadCommonIssues() {
      const { projectId, filter } = this

      const requestFilter = R.filter(Boolean, filter || {})
      return Issue.dispatch('$getList', {
        projectId,
        filter: requestFilter,
      })
    },
    loadCard(payload = { reload: false }) {
      const { cardId, dashboardId } = this
      return DashboardCard.dispatch('$getDetails', {
        cardId,
        dashboardId,
        ...payload,
      })
    },

    loadPermissions() {
      const { $store, issue } = this
      if (!issue) throw new Error("Issue isn't loaded")
      return Promise.all([
        $store.dispatch('permission/getProjectPermissions', { projectId: issue.projectID }),
        // this is forbidden for assignees...
        // $store.dispatch('permission/getIssuePermissions', { projectId: issue.projectID, issueId: issue.id }),
      ])
    },

    loadSchema() {
      const { $store, issue } = this
      if (!issue) throw new Error("Issue isn't loaded")
      return $store.dispatch('issueSchema/get', { projectId: issue.projectID, reload: false })
    },

    loadIssueStatuses() {
      const { $store, issue } = this
      if (!issue) throw new Error("Issue isn't loaded")
      return $store.dispatch('$issueStatus/getList', { projectId: issue.projectID, reload: false })
    },

    loadStatusHistory() {
      const { issueId } = this
      return IssueStatusChange.dispatch('$get', { issueId })
    },

    loadSlaConfig() {
      const { issue } = this
      if (!issue) throw new Error("Issue isn't loaded")
      return SlaConfig.dispatch('$getForProject', { projectId: issue.projectID, reload: false })
    },

    initForm() {
      const { issue } = this
      this.form = issue && R.pick(formFields, issue)
    },

    async save() {
      const { form, issueId } = this
      const issue = { id: issueId, ...form }

      this.saving = true
      try {
        await Issue.dispatch('$update', { issue })
      } catch (e) {
        reportError(e)
      } finally {
        this.saving = false
      }
    },

    setIssueStatus(statusId) {
      if (this.form.status === statusId) return

      this.form.status = statusId
      return this.save()
    },

    downloadAll() {
      const { $store, files } = this
      return Promise.all(files.map(file =>
        axios.head(file.url, { baseURL: null })
          .then(() => {
            const a = document.createElement('a')
            a.href = file.url
            a.download = ''
            a.click()
          }),
      ))
        .catch(e => handleError($store, e).catch(() => {}))
    },

    getFieldDisplayName(fieldName) {
      return this.fieldsLookup?.[fieldName]?.displayName
    },

    truncate(s, length, omission = '…') {
      return R.when(
        R.propSatisfies(R.gt(R.__, length), 'length'),
        R.pipe(R.take(length), R.concat(R.__, omission)),
        s,
      )
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'
@import '../scss/mixins'

.Issue

  &__columns
    display: flex
    //max-width: 1304px
    margin: 0
    background: white
    border-radius: 4px
    min-height: calc(100vh - 64px)

    //& > :first-child
    //  border-top-left-radius: 4px
    //  border-bottom-left-radius: 4px

    //& > :last-child
    //  border-top-right-radius: 4px
    //  border-bottom-right-radius: 4px

  &__side-info
    flex: 0 0 280px
    color: white
    background: var(--v-darkBackground-base)
    overflow: hidden

  &__toolbar
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2) !important

    ::v-deep > .v-toolbar__content
      padding-left: 40px

  &__status
    display: flex
    align-items: center

  &__content
    flex-grow: 1
    padding: 34px 113px
    color: #3C3A52
    overflow: hidden
    transition: all 2000ms ease-in-out

  &__counter
    font-size: 16px
    line-height: 24px
    min-height: 24px
    color: #8B90A0

  &__title
    font-weight: 500
    font-size: 36px
    line-height: 44px
    margin: 22px 0 24px

  &__export-records
    margin: 24px 0

  &__toc
    font-size: 14px
    line-height: 24px
    font-weight: 400
    margin: 24px 0

  &__toc-item a
    text-decoration: none
    &:hover, &:active, &:focus
      text-decoration: underline

  &__field
    margin: 16px 0

.additional
  $x-padding: 40px

  &__section
    padding: 24px $x-padding 25px
    position: relative

    &:after
      content: ''
      display: block
      position: absolute
      bottom: 0
      height: 1px
      width: calc(100% - #{$x-padding * 2})
      background: rgba(0, 0, 0, .12)

  &__title,
  &__title-text
    font-weight: normal
    font-size: 14px
    line-height: 24px
    color: rgba(255, 255, 255, 0.4)

    overflow: hidden
    text-overflow: ellipsis

  &__title
    margin-bottom: 8px

  &__attachment
    display: block
    color: inherit
    text-decoration: none

    &:hover, &:active, &:focus
      color: #69D2EF
</style>
